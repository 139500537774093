/*eslint-disable*/
import React from "react";
import { Link } from "gatsby"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/nextjs-material-kit/components/headerLinksStyle.js";
import headerStyle from "assets/jss/nextjs-material-kit/components/headerStyle.js";

const UseStyles = makeStyles(styles);
const useLinks = makeStyles(headerStyle);

const links = [
  
  // {
  //   href: '/strona-w-budowie',
  //   desc: 'O nas'
  // },
  {
    href: '/news',
    desc: 'Aktualności'
  },
  {
    href: '/wspolnoty',
    desc: 'Wspólnoty'
  // },{
  //   href: '/strona-w-budowie',
  //   desc: 'Intencje'
  // },
  // {
  //   href: '/strona-w-budowie',
  //   desc: 'Terminy'
  // },
  // {
  //   href: '/strona-w-budowie',
  //   desc: 'Galeria'
  },
  {
    href: '/kontakt',
    desc: 'Kontakt'
  },
]

export default function HeaderLinks(props) {
  const classes = UseStyles();
  const linkStyle = useLinks();

  return (
    <List className={classes.list}>
      { links.map((link, index) => (
        <ListItem className={classes.listItem} key={index}>
          <Link to={link.href} className={linkStyle.title}>{link.desc}</Link>
        </ListItem>
      ))}
    </List>
  );
}

const ListItems = () => (
  <>
    <ListItem className={classes.listItem}>
    <CustomDropdown
      noLiPadding
      navDropdown
      buttonText="Components"
      buttonProps={{
        className: classes.navLink,
        color: "transparent"
      }}
      buttonIcon={Apps}
      dropdownList={[
        <Link to="/components">
          <a className={classes.dropdownLink}>All components</a>
        </Link>,
        <a
          href="https://creativetimofficial.github.io/nextjs-material-kit/#/documentation?ref=njsmk-navbar"
          target="_blank"
          className={classes.dropdownLink}
        >
          Documentation
        </a>
      ]}
    />
    </ListItem>
    <ListItem className={classes.listItem}>
      <Button
        href="https://www.creative-tim.com/product/nextjs-material-kit?ref=njsmk-navbar"
        color="transparent"
        target="_blank"
        className={classes.navLink}
      >
        <CloudDownload className={classes.icons} /> Download
      </Button>
    </ListItem>
    <ListItem className={classes.listItem}>
      {/*<Tooltip title="Delete">
        <IconButton aria-label="Delete">
          <DeleteIcon />
        </IconButton>
      </Tooltip>*/}
      <Tooltip
        id="instagram-twitter"
        title="Follow us on twitter"
        placement={"top"}
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          href="https://twitter.com/CreativeTim?ref=creativetim"
          target="_blank"
          color="transparent"
          className={classes.navLink}
        >
          <i className={classes.socialIcons + " fab fa-twitter"} />
        </Button>
      </Tooltip>
    </ListItem>
    <ListItem className={classes.listItem}>
      <Tooltip
        id="instagram-facebook"
        title="Follow us on facebook"
        placement={"top"}
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          color="transparent"
          href="https://www.facebook.com/CreativeTim?ref=creativetim"
          target="_blank"
          className={classes.navLink}
        >
          <i className={classes.socialIcons + " fab fa-facebook"} />
        </Button>
      </Tooltip>
    </ListItem>
    <ListItem className={classes.listItem}>
      <Tooltip
        id="instagram-tooltip"
        title="Follow us on instagram"
        placement={"top"}
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          color="transparent"
          href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
          target="_blank"
          className={classes.navLink}
        >
          <i className={classes.socialIcons + " fab fa-instagram"} />
        </Button>
      </Tooltip>
    </ListItem>
  </>
);
