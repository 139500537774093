import React from "react";

// core components
import TopHeader from "pages-sections/Common/TopHeader.jsx";
import Footer from "components/Footer/Footer.js";

import "./style/style.scss"

export default function Layout({children}) {
  return (
    <div>
      <TopHeader />
        { children }
      <Footer />
    </div>
  );
}
