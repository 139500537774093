import { container, title, subtitle } from "assets/jss/nextjs-material-kit.js";

const landingPageStyle = {
  parallax: {
    background: 'linear-gradient(115deg, #354479 12%, #687cc3 29%, #354479 48%);'
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  gridOffset: { 
    "@media (min-width: 576px)": {
      height: 0,
      padding: 0
    },
  },
  brandStyle: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'    
  },
  brandImg: {
    padding: 0, 
  },
  brandWrapper: {
    padding: '5px 2px 10px',

    "@media (min-width: 576px)": {
      paddingBottom: 0,
      fontSize: '.8rem',
    },

    "@media (min-width: 768px)": {
      fontSize: '.9rem',
    },
  },
  title: {
    ...title,
    textAlign: 'center', 
    fontWeight: 700,
    margin: 0,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#E8BE6D",
    textDecoration: "none",
    fontSize: '1.1rem',
    lineHeight: '2rem',

    "@media (min-width: 576px)": {
      fontSize: '1.3rem',
      lineHeight: '2.4rem',
    },
    
    "@media (min-width: 768px)": {
      fontSize: '2rem',
      lineHeight: '3rem'
    },
    
    "@media (min-width: 992px)": {
      fontSize: '2.8rem',
      lineHeight: '4rem'
    },

    "@media (min-width: 1200px)": {
      marginTop: "30px",
      fontSize: '3.5rem',
      lineHeight: '4.5rem'
    },
  },
  subtitle: {
    ...subtitle,
    color: "#E8BE6D",
    fontWeight: 400,
    maxWidth: "500px",
    
    fontSize: '1.1rem',
    lineHeight: '2rem',
    
    "@media (min-width: 576px)": {
      fontSize: '1.3rem',
      lineHeight: '2.4rem',
    },
    
    "@media (min-width: 768px)": {
      fontSize: '2rem',
      lineHeight: '3rem'
    },
    
    "@media (min-width: 992px)": {
      fontSize: '2.8rem',
      lineHeight: '4rem'
    },

    "@media (min-width: 1200px)": {
      marginTop: "10px",
      fontSize: '3rem',
      lineHeight: '4.5rem'
    },
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "0 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  }
};

export default landingPageStyle;
