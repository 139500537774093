import * as React from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const UseStyles = makeStyles(styles);

const dashboardRoutes = [];

const TopHeader = props => {
  const classes = UseStyles();
  const { ...rest } = props;  
  
  return (
    <>
      <Parallax className={classes.parallax}>
        <div className={classes.container}>
          <GridContainer >
            <GridItem xs={3} sm={12} className={classes.gridOffset}/>
            <GridItem xs={8} sm={6} md={6} className={classes.brandImg}>
              <img src='/pneumatoforaposwiatazlota3.png' alt='baner' style={{maxWidth: '50%'}}/>
            </GridItem>
              <GridItem xs={12} sm={6} className={classes.brandWrapper}>
                <div className={classNames(classes.brand, classes.brandStyle)}>
                  <h1 className={classes.title}>Katolicka Odnowa w&nbsp;Duchu Świętym</h1>
                  <h2 className={classes.subtitle}> diecezji opolskiej</h2>
                </div>
              </GridItem >
              <div style={{
                width: '100%',
                borderTop: '1px solid white',
                background: '#ffffff30',
                height: '63px',
                position: 'absolute',
                bottom: '0',
                left: 0,
                zIndex: 0
              }}/>
              <Header
                color="transparent"
                routes={dashboardRoutes}
                brand="Start"
                rightLinks={<HeaderLinks />}

                {...rest}
              />
          </GridContainer>
        </div>
      </Parallax>
    </>
)};

export default TopHeader;